import React, { Component } from 'react';
import GatsbyLink from '../GatsbyLink';
import ChevronUp from '../SVG/ChevronUp';
import './HeaderNav.scss'

const isClient = typeof window !== 'undefined';

const NavItem = (props) => {
  const { item, baseUrl, openNavKey, toggleSubNav, toggleNav, active: navActive } = props;

  /**
   * Allow user to scroll freely
   */
  const enableBodyScroll = () => {
    if (isClient) {
      document.body.classList.remove('nav-open');
    }
  }

  /**
   * Lets close the navigation on selecting a link when its open.
   */
  const handleOnClick = () => {
    enableBodyScroll();
    toggleNav();
  }

  const {
    object_id,
    title,
    url,
    children,
    target,
    classes
  } = item;

  const isOpen = openNavKey === object_id;

  return (
    <span key={object_id} className={classes}>
      <GatsbyLink to={url} onClick={() => handleOnClick()} target={target ? target : null} activeClassName="active" aria-label={title} decode={false} dangerouslySetInnerHTML={{ __html: title }} />
      {children && children[0].url !== '#gatsby' && (
        <>
          <NavChildren enableBodyScroll={enableBodyScroll} handleOnClick={handleOnClick} baseUrl={baseUrl} childNav={children} isOpen={isOpen} />
          <button
            type="button"
            className={`expand ${isOpen ? 'open' : 'closed'}`}
            onClick={event => toggleSubNav(event, object_id)}
            aria-label="Open Menu"
          >
            <ChevronUp />
          </button>
        </>
      )}
    </span>
  );
}

const NavChildren = (props) => {
  const { childNav, isOpen = null, handleOnClick } = props;
  return (
    <ul className={`children ${isOpen ? 'open' : 'close'}`}>
      {childNav.map((child, index) => {
        const {
          classes,
          title,
          url,
          target
        } = child;
        return (
          <li
            key={index} className={classes}
          >
            <GatsbyLink to={url} onClick={handleOnClick} target={target ? target : null} activeClassName="active" aria-label={title} decode={false} dangerouslySetInnerHTML={{ __html: title }} />
          </li>
        )
      })}
    </ul>
  );
}
export default class HeaderNav extends Component {
  state = { openNavKey: null };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleNav(event);
  }

  toggleSubNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    return this.setState({ openNavKey });
  }

  render() {
    const { active, navItems, baseUrl, className, toggleNav } = this.props;
    const { openNavKey } = this.state;
    return (
      <ul className={className}>
        {navItems && navItems.map((item, index) => index < 10 && (
          <li className="nav-item" key={index}>
            <NavItem key={item.url} toggleNav={toggleNav} active={active} item={item} baseUrl={baseUrl} toggleSubNav={this.toggleSubNav} openNavKey={openNavKey} />
          </li>
        ))}
      </ul>
    );
  }
}
